@import '../Styles/global.css';

.cardBody {
    text-align: center;

}

.cardTitle {
    text-align: center;
    padding-top: 1%;

}

.subtitle {
    text-align: center;
    font-size: 50%;
    margin-left: 20%;
    margin-right: 20%;
}

.descCard {

    margin-left: 20%;
    margin-right: 20%;
}

.topLevelTitle {

    font-size: 260%;
}

.secondLevelTitle {

    font-size: 190%;
}

.greenDot {
    height: 50px;
    width: 50px;
    background-color: #7FEB59;
    border-radius: 70%;
    display: inline-block;
}

.blueDot {
    height: 50px;
    width: 50px;
    background-color: #4FC0F6;
    border-radius: 70%;
    display: inline-block;
}

.dot {
    height: 50px;
    width: 50px;
    background-color: #bbb;
    border-radius: 70%;
    display: inline-block;
}

.arrowRight {
    margin-top: 16px;
    width: 0;
    height: 0;
    margin-right: -1.3vw;
    margin-left: 1vw;
    padding-left: 1%;
    
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    display: inline-block;
    border-left: 10px solid gray;

}

.arrowBox {
    margin-top: 25px;
    z-index: -1;
    position: relative;
    margin-right: -1.3vw;
    width: 4vw;
    height: 2px;
    border: gray;
    background-color: gray;
    display: inline-block;
}


.progressText {
    margin-left: 10px;
    margin-top: 60px;


}



.floatContainer {
    width: 27vw;
    margin: auto;
    padding-bottom: 4%;


}

.floatChild {

    float: left;


}

.PageTitle {
    width: auto;
    margin: auto;
    text-align: center;
}

.PageTitleHeader {
    width: auto;
    margin-left: -15%;
    text-align: center;
}

div.item {
    vertical-align: top;
    display: inline-block;
    text-align: center;
    width: 120px;
    margin-right: -9%;
    /* margin-left: -4%; */

}


.caption {
    display: block;
    text-align: center;
}

.journeyBlock {
    margin: auto;
    /* width: 1500px; */
    /* margin-left: -10%; */
    width: 100%;
    text-align: center;

}

.fillCard {
    width: 100%
}

.standards {
    border-width: 1px;
    border-radius: 10px;
    padding: 20px;
    border-color: gray;
    border-style: solid;
}

/* .journey-card{

    width: 100%;

    margin-left:-20%;

} */
.journeyCard {
    /* min-width: 130%;
margin-left: -10%; */
    /* margin-left:-20%; */
}

.rotatedCol {

    margin-right: -17%;
}

.cardRow {

    margin-bottom: 3%;
}

.rotatedTitle {
    writing-mode: vertical-lr;
    rotate: -180deg;
    text-orientation: center;
}

.propertyRow {
    width: 110%;
    min-width: 200px !important;
    text-align: center;

}

.dropdown {
    width: 100%;
}

.evidence {
    width: auto;
    margin-left: 10%;
    text-align: center;
}

.analysisTopRow {
    height: 95%;
}

.concepts {
    height: 250px;
    overflow: auto;
    margin-bottom: 5%;
}

.userName {
    font-weight: bold;
    font-size: 16px;
}

.messageDate {
    font-size: 13px;
    color: #afacac;
}

.userName+.messageDate {
    margin-left: 10px;
}

.comments {

    background-color: white;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100
}

.commentSection > * {
    padding: 0 20px;
}

.Comment {
    border-bottom: var(--light-border);
    border-top: var(--light-border);
}

.navTabs .navItem.show .navLink,
.navTabs .navLink.active {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff
}

.navTabs .navItem.show .navLink,
.navTabs .navLink.active {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff
}

.nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.navLink {
    display: block;
    padding: .5rem 1rem
}

.navLink:focus,
.navLink:hover {
    text-decoration: none
}

.navLink.disabled {
    color: #6c757d
}

.navTabs {
    border-bottom: 1px solid #dee2e6
}

.navTabs .navItem {
    margin-bottom: -1px
}

.navTabs .navLink {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem
}

.navTabs .navLink:focus,
.navTabs .navLink:hover {
    border-color: #e9ecef #e9ecef #dee2e6
}

.navTabs .navLink.disabled {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent
}

.comments {
    width: 120px;
    float:right;
    position:fixed;
    z-index:1000;
    margin-right:20px;
    margin-bottom: 10px;
    top:100px;
    right:15px;
}

.faqSegment {
    margin-top: 10px;
}

.faqButton {
    background: none;
    border: none;
    box-shadow: none;

    color: var(--blue);
    text-align: left;
    font-size: var(--font-subheading-size);
    cursor: pointer;
}

.faqAnswer {
    color: var(--grey);
    font-size: var(--font-subheading-size);
}

@media only screen and (max-width: 768px) {
    .comments {
        top: 160px;
    }
}