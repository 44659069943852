@import './global.css';

/* Modal Main */
.modal {
    width: 350px;
    border-radius: 0;
}

.modalHeader{
    font-size: var(--modal-header-font-size);
    color: var(--modal-header-font-color);
    font-family: Quicksand;
    font-style: normal;
    font-weight: 400;
    background-color: var(--modal-header-background-color);
    border-bottom: var(--modal-header-border);
    border-radius: 0;
}

.modalBody_noScroll,
.modalBody{
    font-size: var(--modal-body-font-size);
    color: var(--modal-body-font-color);
    padding: 10px 20px;
    background-color: var(--modal-body-background-color);
    box-shadow: none;
    border-radius: 0;
    max-height: 70vh;
}

.modalBody {
    overflow-y: scroll;
}
.confirmationButtonContainer {
    display: grid;
    grid-template-columns: auto auto;
    justify-items: center;
}

.modalButton {
    min-width: 100px;
    border-radius: 0;
}

.modalButtonSmall {
    margin: 10px;
}

.confirmationButton {
    min-width: 100px;
}

