@import '../../../../components/Styles/global.css';

.tableControls {
  display: grid;
  grid-template-columns: 75px 200px;
  gap: 10px;
}

.tableContainer {
  background: var(--white);
  padding: 10px ;
  padding-top: 20px;
  overflow-x: auto;
  padding-right: 10px;
  overflow-y: visible;
  margin-bottom: 20px;
}

.matrixDropdown {
  background-color: var(--white);
  border: 1px solid var(--grey-fade-half);
  border-radius: 4px;
  padding: 10px;
  width: 100%;
}

.stickyHeader {
  background: white;
  position: sticky !important;
  top: 0  !important; 
  z-index: 50;
}

.stickyColumn {
  background: white !important;
  position: sticky;
 
  left: 0 !important;  
  
}
.stickyBar {
  display: flex;
  width: 100%;
  height: 25px;
  font-size: 14px;
  vertical-align: center;
  color:#777d83;
  text-align: center;
  z-index: 10;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}
 

.noCollapse {
  border-collapse: separate;
  border-spacing: 0;
}
/*  
/* 
table {


  margin: 0;
  border: none;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  border: 1px solid black;
}
table td,
table th {
  border: 1px solid black;
  padding: 0.5rem 1rem;
}
table thead th {
  padding: 3px;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 25vw;
  background: white;
}
table td {
  background: #fff;
  padding: 4px 5px;
  text-align: center;
}

table tbody th {
 
  position: relative;
}

table tbody tr:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
  background: white;
}

caption {
  text-align: left;
  padding: 0.25rem;
  position: sticky;
  left: 0;
} */
/* table tbody th {
  position: sticky;
  left: 0;
  background: white;
  z-index: 1;
}
table thead th:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
  background: white;
}
table thead th {
  padding: 3px;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 25vw;
  background: white;
}
table th {
  border: 1px solid black;
  padding: 0.5rem 1rem;
} */