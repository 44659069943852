.editor {
  box-sizing: border-box;
  border: 1px solid rgb(131, 131, 131);
  cursor: text;
  padding: 16px;
  border-radius: 5px;
  margin: 0 10px;
  /* margin-bottom: 2em; */
  background: #fefefe;
}

.editor :global(.public-DraftEditor-content) {
  min-height: 100px;
  max-height: 100px;
  overflow: scroll;
}
