// 
// menu.scss
//
.menu-teams-list{
    padding: 0 15px;
    list-style:none;
    max-height:40vh;
    overflow: hidden;
}
.menu-teams-list:hover{
    // padding:0;
    list-style:none;
    max-height:40vh;
    
  overflow-y: scroll;
}
// Logo
.logo {
    display: block;
    // line-height: $topbar-height;
    width: $leftbar-width;
    margin-left: auto;
    margin-right: auto;
    top: 0;

    span.logo-lg {
        display: block;
    }
    span.logo-sm {
        display: none;
    }
}

// Wrapper
.wrapper {
    height: 100%;
    overflow: hidden;
    width: 100%;

    display: grid;
    grid-template-columns: $leftbar-width auto;
}

//Content Page
.content-page {
    overflow: hidden;
    padding: $topbar-height 12px 65px;
    min-height: 100vh;
    background: #F2F2F2;
    grid-column: 2;
}

// Left Side-menu (Default dark)
.left-side-menu {
    // display: none; //THIS WILL TURN THE SIDEBAR OFF
    width: $leftbar-width;
    grid-column: 1;
    z-index: 9999;
    background: $bg-leftbar;
    bottom: 0;
    position: fixed;
    transition: all .2s ease-out;
    top: 70px;
    //padding-top: $topbar-height;
    // box-shadow: 8px 0px 22px 0px rgba(171, 161, 171, 0.25);

    .simplebar-menu {
        width: 200px; 
        height: calc(100vh - 70px);
    }
}

.condense {
    // margin: 0 10px 0 10px;
    font-size: 2rem;

    position: absolute;
    left: 20px;
    bottom: 10px;
}

.condense-title {
    font-size: 1rem;
    color: $menu-item;
}

/* 
 * Highlight the inactive pages
 * Uses the first 2 filters to convert the icon colour to black
 * Then uses various other filters to match the required colour
 */
.side-nav-highlight {
    filter: brightness(0) saturate(100%) invert(44%) sepia(4%) saturate(906%) hue-rotate(167deg) brightness(101%) contrast(86%);
}

// Highligh inactive Devices and Teams page
// Much simpler, just changes the colours as they use text characters for icons
.side-nav-highlight::before {
    color: #6C757D;
}

.simplebar-content {
    padding: 140px 0px 0px;
}

// Side-nav
.side-nav {
    .side-nav-link {
        color: $menu-item;
        display: block;
        padding: 5px 0px;
        font-size: 14px;
        position: relative;
        transition: all 0.4s;
        &:hover,
        &:focus,
        &:active {
            color: $menu-item-hover !important;
            text-decoration: none;
            cursor: pointer;
        }

        span {
            vertical-align: middle;
        }

        i {
            display: inline-block;
            line-height: 1.0625rem;
            margin: 0 10px 0 0;
            font-size: 30px;
            vertical-align: middle;
            width: 20px;
        }

        img {
            display: inline-block;
            line-height: 1.0625rem;
            margin: 0 10px 0 10px;
            font-size: 30px;
            vertical-align: middle;
            width: 20px; 
        }
    }

    .side-nav-item {
        i {
            display: inline-block;
            line-height: 1.0625rem;
            margin: 0 10px 0 10px;
            font-size: 30px;
            color: $menu-item-hover;
        }
    }

    .side-nav-header {
        color: $menu-item-light;
        display: flex;
        align-items: center;
        padding: 5px 0px;
        position: relative;

        &:hover {
            cursor: pointer;
        }
        
        span {
            vertical-align: middle;
            display: inline-block;
        }

        
    }

    .menu-arrow {
        transition: transform .15s;
        position: absolute;
        right: 30px;
        display: inline-block;
        font-family: 'Material Design Icons';
        text-rendering: auto;
        line-height: 1.5rem;
        font-size: 1.1rem;
        transform: translate(0, 0);
        &:before {
            content: "\F142";
        }
    }

    .badge{
        margin-top: 3px;
    }

    .side-nav-item {
        &.mm-active {
            > a {
                .menu-arrow {
                    transform: rotate(90deg);
                }
            }
        }
    }

    .side-nav-title {
        //padding: 12px 30px;
        letter-spacing: .05em;
        pointer-events: none;
        cursor: default;
        font-size: 14px;
        //text-transform: uppercase;
        color: $menu-item;
        font-weight: $font-weight-normal;
    }

    .mm-active {
        > a {
            color: $menu-item-active !important;
        }
    }
}

// Enlarge menu
body[data-leftbar-compact-mode="condensed"] {
    // min-height: 1600px;

    .wrapper {
        grid-template-columns: $leftbar-width-condensed auto;

        // Side menu
        .left-side-menu {
            position: absolute;
            padding-top: 0;
            width: 70px;
            overflow: hidden;
            z-index: 9999;
            //padding-top: $topbar-height;
            top: 70px;
    
            .simplebar-mask,
            .simplebar-content-wrapper {
                overflow: visible !important;
            }

            .simplebar-scrollbar {
                display: none !important;
            }

            .simplebar-offset {
                bottom: 0 !important;
            }

            .open-teams,
            .open-devices {
                //width: $leftbar-width;

                .side-nav-second-level {
                    position: fixed;
                    background-color: $bg-leftbar-light;
                    left: 75px;
                    transform: translateY(-42%);

                    // box-shadow: 8px 0px 22px 0px rgba(171, 161, 171, 0.25);
                }
            }

            .simplebar-menu {
                width: $leftbar-width-condensed;
            }

            .logo {
                width: 70px;
                z-index: 1;
                background: $bg-leftbar;
            }
	    .metismenu {
                .has-arrow {
                    &:after {
                        border-width: 0;
                    }
                }
            }
        }
    
        // Help Box
        .help-box {
            display: none;
        }
    
        //Navbar & Footer
        .navbar-custom {
            left: 0;
        }

        .footer {
            left: 50px;
        }
    }

    .side-nav-header {
        img {
            font-size: 25px;
            margin: 0 10px 0 22px !important;
        }
    }

    // Sidebar Menu
    .side-nav {
        .side-nav-title,
        .menu-arrow,
        .badge,
        .collapse.in,
        .mm-collapse {
            display: none !important;
        }
        .nav.collapse {
            height: inherit !important;
        }
        
        .side-nav-item {
            position: relative;
            white-space: nowrap;

            .side-nav-header,
            .side-nav-link {
                //padding: 15px 20px;
                // min-height: 56px;
                // transition: none;
    
                &:hover,
                &:active,
                &:focus {
                    color: $menu-item-hover;
                }

                i {
                    font-size: 30px;
                    margin-left: 20px;
                }

                img {
                    font-size: 25px;
                    margin: 0 10px 0 22px;
                }

                i,
                img {
                    margin-right: 30px;
                    
                }

                span {
                    display: none;
                    padding-left: 10px;
                }

                .side-nav-title {
                    display: none;
                }
            }
            
            &:hover  {

                .side-nav-link {
                    // position: relative;
                    // color: $menu-item-hover;

                    span {
                        display: inline;
                    }
                }

                /*> ul {
                    display: block !important;
                    left: 70px;
                    position: absolute;
                    width: 190px;
                    height: auto !important;
                    padding: 5px 0;
                    z-index: 9999;
                    //background: $bg-leftbar;
                    a {
                        padding: 8px 20px;
                        position: relative;
                        width: 190px;
                        &:hover {
                            color: $menu-item-hover;
                        }
                    }

                    ul {
                        left: 190px;
                        top: 0;
                    }
                }*/
            }
        }
    }

    .logo {
        span.logo-lg {
            //display: none;
        }
        span.logo-sm {
            display: block;
            line-height: 70px;
            color: $primary;
        }
    }
}

@include media-breakpoint-down(sm) {
    body {
        overflow-x: hidden;
    }
    .left-side-menu {
        // box-shadow: $shadow;
        display: none;
        z-index: 9999 !important;
        padding-top: $topbar-height;

        // .simplebar-menu {
        //     height: calc(100vh - 70px);
        // }
    }
    .sidebar-enable {
        .left-side-menu {
            display: block;
        }
    }

    .simplebar-content {
        padding: 70px 0px 0px !important;
    }

    .navbar-nav.navbar-right {
        float: right;
    }

    
    .content-page {
        margin-left: 0 !important;
        padding: 65px 10px 65px;
        grid-column: 1;
    }
    body[data-leftbar-compact-mode="condensed"] {
        .left-side-menu {
            margin-left: -70px;
        }
    }
    .logo {
        span.logo-lg {
            display: block;
        }
        span.logo-sm {
            display: none;
        }
    }

    .wrapper {
        grid-template-columns: auto;
    }

    .content {
        display: grid;
        grid-template-columns: auto;

        .navbar-custom > .row {
            display: grid;
            // grid-template-columns: 1fr 1fr;

            .col > .logoDiv {
                float: none;
            }

            .topbar-col {
                display: grid;
                grid-template-columns: 1fr 1fr;

                ul {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                }
            }
        }

        .container-fluid {
            margin-top: 100px;
        }
    }
}

/// Help-box
.help-box {
    border-radius: 5px;
    padding: 20px;
    margin: 65px 25px 25px;
    position: relative;
    background-color: $help-box-light-bg;
    
    .close-btn {
        position: absolute;
        right: 10px;
        top: 10px;
    }
}
// Light
body[data-leftbar-theme="light"] {
    .help-box {
        background-color: $primary;
    }
}

// Layout -topnav
body[data-layout="topnav"] {
    .content-page {
        margin-left: 0 !important;
        padding: 0 0 60px 0;
    }
}

// Layout-boxed
body[data-layout-mode="boxed"] {
    background-color: $boxed-layout-bg;

    .wrapper {
        max-width: $boxed-layout-width;
        margin: 0 auto;
        background-color: $body-bg;
        // box-shadow: $shadow;
    }

    &[data-leftbar-compact-mode="condensed"] {
        .logo {
            position: relative;
            margin-top: -$topbar-height;
        }
    }
}

// Scrollable Layout

@include media-breakpoint-up(xl) {
    body[data-leftbar-compact-mode="scrollable"] {
        padding-bottom: 0;
        
        .wrapper {
            display: flex;
        }

        .left-side-menu {
            position: relative;
            min-width: $leftbar-width;
            max-width: $leftbar-width;
            padding-top: 0;
        }

        .logo {
            position: relative;
            margin-top: 0;
        }

        .content-page {
            margin-left: 0;
            width: 100%;
            padding-bottom: 60px;
        }
    }
}

// Detached Left sidebar

body[data-layout="detached"] {
    padding-bottom: 0;

    @include media-breakpoint-up(lg) {
        .container-fluid {
          max-width: 95%;
        }
    }

    &[data-layout-mode="boxed"] {
        .wrapper {
            max-width: 100%;
        }
    }

    &[data-leftbar-compact-mode="scrollable"] {
        .wrapper {
            padding-top: 70px;
        }
    }
    .wrapper {
        display: flex;
        overflow: inherit;
    }
    .content-page {
        margin-left: 0;
        overflow: hidden;
        padding: 0 15px 5px 30px;
        position: relative;
        margin-right: -15px;
        width: 100%;
        padding-bottom: 60px;
    }

    .left-side-menu {
        position: relative;
        background: $bg-detached-leftbar !important;
        min-width: $leftbar-width;
        max-width: $leftbar-width;
        // box-shadow: $shadow;
        margin-top: 30px;
        padding-top: 0 !important;
        z-index: 1001 !important;
    
        .side-nav {
            .side-nav-link {
                color: $menu-item-dark !important;
                &:hover,
                &:focus,
                &:active {
                    color: $menu-item-dark-hover !important;
                }
            }
    
            .mm-active {
                > a {
                    color: $menu-item-dark-active !important;
                }
            }
        
            .side-nav-title {
                color: $menu-item-dark;
            }
        }
    }
    
    .leftbar-user {
        background: url("../../../images/waves.png") no-repeat;
        padding: 30px 20px;
        text-align: center;
    
        .leftbar-user-name {
            font-weight: 700;
            color: $dark;
            margin-left: 12px;
            margin-top: 8px;
            display: block;
        }
    }

    @include media-breakpoint-down(sm) {
        &.sidebar-enable {
            .left-side-menu {
                position: fixed;
                left: 0;
                overflow-y: auto;
                margin-top: 0;
            }
        }   
        .wrapper {
            max-width: 100%;
        } 
        .content-page {
            margin-left: 0 !important;
            padding: 0 10px 60px 10px;
        }

        .lang-switch {
            display: none;
        }
    }

    // Enlarge menu
    &[data-leftbar-compact-mode="condensed"] {
        .wrapper {
            // Side menu
            .left-side-menu {
                max-width: 70px;
                min-width: 70px;
                position: relative;
                top: 70px;
            }
            .leftbar-user {
                display: none;
            }
        
            // Content Page
            .content-page {
                margin-left: 0;
            }
        
            //Footer
            .footer {
                left: 0;
            }
        }
        // Sidebar Menu
        .side-nav {
            .side-nav-item {

                // .side-nav-link {
                //     &:hover,
                //     &:active,
                //     &:focus {
                //         color: $menu-item-hover;
                //     }
                // }
                
                &:hover  {
                    .side-nav-link {
                        background: $primary;
                        // color: $menu-item-hover!important;
                        // transition: none;
                    }

                    > ul {
                        background: $bg-leftbar-light;
                        // box-shadow: $shadow;

                        a {
                            &:hover {
                                color: $menu-item-light-hover;
                            }
                        }
                    }
                }
            }
        }
    }

}

.button-menu-mobile {
    .lines {
        width: 18px;
        display: block;
        position: relative;
        height: 16px;
        transition: all .5s ease;
        margin-top: 26px;
        margin-left: 10px;
    }
    span {
        height: 2px;
        width: 100%;
        background-color: rgba($white, 0.8);
        display: block;
        margin-bottom: 5px;
        transition: transform .5s ease;

        &:nth-of-type(2) {
            width: 24px;
        }
    }

    &.disable-btn {
        display: none;
    }
}

// Light sidebar

body[data-leftbar-theme="light"] {
    .left-side-menu {
        background: $bg-leftbar-light;

        .logo {
            background: $bg-leftbar-light !important;
        }
    }
    
    .side-nav {
        .side-nav-link {
            color: $menu-item-light;
            // &:hover,
            // &:focus,
            // &:active {
            //     color: $menu-item-light-hover;
            // }
        }
    
        .mm-active {
            > a {
                color: $menu-item-hover !important;
            }
        }
    
        .side-nav-title {
            color: $menu-item-light;
        }
    }

    // Enlarge menu
    &[data-leftbar-compact-mode="condensed"] {
        // Sidebar Menu
        .side-nav {
            .side-nav-item {

                .side-nav-link {
                    // &:hover,
                    // &:active,
                    // &:focus {
                    //     color: $menu-item-hover;
                    // }
                }
                
                &:hover  {
                    // .side-nav-link {
                    //     color: $menu-item-hover !important;
                    //     transition: none;
                    // }

                    > ul {
                        // box-shadow: $shadow;

                        a {
                            &:hover {
                                color: $menu-item-light-hover;
                            }
                        }
                    }
                }
            }
        }
    }
}

// Dark sidenav
body[data-leftbar-theme="dark"] {
    .left-side-menu {
        background: $bg-leftbar-dark;

        .logo {
            background: $bg-leftbar-dark !important;
        }
    }

    .side-nav {
        .side-nav-link {
            color: $sidebar-dark-menu-item;
            // &:hover,
            // &:focus,
            // &:active {
            //     color: $sidebar-dark-menu-item-hover;
            // }
        }
    
        .mm-active {
            > a {
                color: $sidebar-dark-menu-item-active !important;
            }
        }
    
        .side-nav-title {
            color: $sidebar-dark-menu-item;
        }
    }

    // Enlarge menu
    &[data-leftbar-compact-mode="condensed"] {
        // Sidebar Menu
        .side-nav {
            .side-nav-item {

                .side-nav-link {
                    // &:hover,
                    // &:active,
                    // &:focus {
                    //     color: $sidebar-dark-menu-item-hover;
                    // }
                }
                
                &:hover  {
                    // .side-nav-link {
                    //     color: $menu-item-hover !important;
                    //     transition: none;
                    // }

                    > ul {
                        // box-shadow: $shadow;

                        a {
                            &:hover {
                                color: $sidebar-dark-menu-item-hover;
                            }
                        }
                    }
                }
            }
        }
    }
}