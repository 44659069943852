@import './Styles/global.css';

.AIMenuButton {
  background: none;
  border: none;
  box-shadow: none;

  color: var(--blue);
  text-align: left;
  font-size: var(--font-subheading-size);
  cursor: pointer;
}


.offcanvas-backdrop{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  display: none !important;
}

.AIChat {
  color: var(--grey);
  font-size: var(--font-heading-size);
}

.AIDisclaimer {
  color: var(--grey);
  font-size: var(--font-tiny-size);
}

.AIMenu {
  width: 40vw;
}


.cardHeader{
  min-height: 10vh;
  height: auto;
  font-size: var(--card-header-font-size);
  color: var(--card-header-font-color);
  background-color: var(--card-header-background-color);
  font-family: Quicksand;
  font-style: normal;
  font-weight: 400;
}

.card {
  box-shadow: none;
  border: none;
}

.deviceInfo {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 18px;
  
  /* identical to box height */
  display: flex;
  align-items: center;
  
  color: #6C757D;
}
  
.greenCounter,
.blueCounter,
.redCounter,
.greyCounter,
.yellowCounter,
.orangeCounter {
  display: inline-block;
  min-width: 4.5vw;
  text-align: center;
  padding: 2px 10px 2px 10px;
}

.blueCounter{
  color: var(--blue-counter-font-color);
  background: var(--blue-counter-background-color);
}
.greenCounter{
  color: var(--green-counter-font-color);
  background: var(--green-counter-background-color);
}
.greyCounter{
  color: var(--grey-counter-font-color);
  background: var(--grey-counter-background-color);
}
.redCounter{
  color: var(--red-counter-font-color);
  background: var(--red-counter-background-color);
}

.orangeCounter{
  color: var(--orange-counter-font-color);
  background: var(--orange-counter-background-color);
}
.yellowCounter{
  color: var(--yellow-counter-font-color);
  background: var(--yellow-counter-background-color);
}
.deviceSubheading {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-subheading-size);
  line-height: 20px;

  /* identical to box height */
  display: flex;
  align-items: center;

  color: var(--font-subheading-color);
}

.deviceHeading{

  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-heading-size);
  line-height: 22px;
  
  /* identical to box height */
  /* display: flex; */
  /* align-items: center; */
  
  color:#6C757D !important;
  /* // var(--font-heading-color); */
}

.cardBody{
  
}

.arrow{
    z-index: 100;
}

.btnGroup {
    margin-left: 5px;
    margin-bottom: 5px;
}

.li{
    margin-bottom: 1%;
}

.formInline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

/* Add some margins for each label */
.formInline label {
  margin: 5px 10px 5px 0;
}

/* Style the input fields */
.formInline input {
  vertical-align: middle;
  margin: 5px 10px 5px 0;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
}

.tableRowReq{
  border-bottom: var(--light-border);
  padding: 5px;
  cursor:pointer;
  color:var(--card-header-font-color);
  /* text-decoration:underline; */
  margin-bottom: -4px;
}

.testLink{
  width: 100%;
  color:black;
  text-decoration: none !important;    
}

.testLink:hover{  
  color:black !important;
  text-decoration: none !important;   
}

.tableRowHeader{
  border-width: 1px !important;
  border: lightgray;
  border-top-style: none;
  border-top-width: medium;
  border-right-style: none;
  border-right-width: medium;
  border-bottom-style: none;
  border-bottom-width: medium;
  border-left-style: none;
  border-left-width: medium;
  border-style: solid;
  border-radius: 5px;
  padding: 5px;
  cursor:pointer;
  color:black;
  /* text-decoration:underline; */
  margin-bottom: -4px;
}

.tableRowReq:hover{
  background-color: #e6e6e6 ;
}

.stickyWindow {
  /* position: -webkit-sticky; Safari */
  position: fixed;
  width: 27vw;
  z-index: 100;
  /* margin-top: 8vh; */
  /* top: 100vh; */
}

.visuallyHidden{
  display: none;
}

.tableRowReqSelect{
  border-width: 1px !important;
  border: lightgray;
  border-top-style: none;
  border-top-width: medium;
  border-right-style: none;
  border-right-width: medium;
  border-bottom-style: none;
  border-bottom-width: medium;
  border-left-style: none;
  border-left-width: medium;
  border-style: solid;
  border-radius: 5px;
  padding: 5px;
  cursor:pointer;
  color:black;
  /* text-decoration:underline; */
  margin-bottom: -4px;
  background-color: #e6e6e6 ;
}

.boardsSwitch {
  padding-bottom: 5%;
  margin-right: 3%;
}

.tableSwitch {
  padding-bottom: 5%;
  margin-left: 1%;
}

.requirementRow {
  padding: 20px 0;
}

.testContainer {
  width: 100%;
}

.requirementContainer {
  margin-bottom: 0;
  margin-top: 20px;

  border-bottom: var(--light-border);
}

.requirementInfo {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  align-items: center;

  margin: 5px 0;
}

.requirementInfoElement {
  width: 100%;
}

.requirementGroup {
  margin: 0;
}

.requirementList {
  border: var(--light-border);
  padding: 0;
  margin: 0;
}

.tileListContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));

  margin-top: 20px;
  width: 100%;
}

.tasksReqs {
  width: auto;
  padding: 0 5px;
}

.taskHeader {
  font-size: var(--font-heading-size);
  color: var(--font-heading-color);

}

.tileContainer {
  display: grid;
  grid-template-columns: 4fr 1fr 0 1fr;
}

.tilesList {
  padding: 10px 20px;
  overflow-y: auto;
  overflow-x: visible;
  height: 50vh;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: calc(.25rem - 1px);
}

.sectionTitle {
  font-size: var(--card-header-font-size);
  color: var(--card-header-font-color);
}

.cardDesc {
  text-align: left;
}

.cardDescHeader {
  background-color: var(--card-header-background-color);
  font-size: var(--card-header-font-size);
  color: var(--card-header-font-color);
  text-align: left;
}

.cardDescBody {
  background-color: var(--card-body-background-color);
  font-size: var(--card-body-font-size);
  color: var(--card-body-font-color);
  text-align: left;
}


.deviceSubheading {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-subheading-size);
  line-height: 20px;

  /* identical to box height */
  display: flex;
  align-items: center;

  color: var(--font-subheading-color);

  margin-bottom: 40px;
}

.AIMenuButton {
  background: none;
  border: none;
  box-shadow: none;

  color: var(--blue);
  text-align: left;
  font-size: var(--font-subheading-size);
  cursor: pointer;
}