body {
    /* Colors */
    --blue:                             #35A8E0;
    --purple:                           #583d87;
    --blue-fade:                        #35A8E033;
    --red:                              #F95D7D;
    --red-fade:                         #F95D7D33;
    --green:                            #59C123;
    --green-fade:                       #59C12333;
    --orange:                           #FF985E;
    --orange-fade:                      #FF985E33;
    --grey:                             #6C757D;
    --grey-fade:                        #6C757D33;
    --grey-fade-half:                   #6C757D80;
    --grey-fade-again:                   #dae0e6ab;
    --black:                            #000000;
    --yellow:                           #918700;
    --yellow-fade:                      #caaf0280;
    --white:                            #FFFFFF;

    /* Font Variables */
    --font-heading-size:                15px;
    --font-subheading-size:             13px;
    --font-normal-size:                 12px;
    --font-link-size:                   12px;
    --font-tiny-size:                   10px;
    --font-heading-color:               var(--grey);
    --font-subheading-color:            var(--grey); 
    --font-normal-color:                var(--black);
    --font-link-color:                  var(--blue);

    /* Border Variables */
    --light-border:                     solid 1px var(--grey-fade-again);

    /* Card Variables */
    --card-header-background-color:     var(--white);
    --card-header-font-color:           var(--grey);
    --card-header-font-size:            15px;
    --card-header-border:               var(--light-border);
    --card-body-background-color:       var(--white);
    --card-body-font-color:             var(--black);
    --card-body-font-size:              12px;

    /* Modal Variables */
    --modal-header-background-color:    var(--white);
    --modal-header-font-color:          var(--blue);
    --modal-header-font-size:           var(--font-heading-size);
    --modal-header-border:              var(--light-border);
    --modal-body-background-color:      var(--white);
    --modal-body-font-color:            var(--grey);
    --modal-body-font-size:             12px;

    /* Table Variables */
    --table-header-font-size:           13px;
    --table-header-font-color:          var(--grey);
    --table-header-background-color:    var(--white);
    --table-body-font-size:             12px;
    --table-body-font-color:            var(--black);
    --table-body-background-color:      var(--white);

    /* Counters */
    --green-counter-background-color:   var(--green-fade) !important;
    --green-counter-font-color:         var(--green) !important;
    --blue-counter-background-color:    var(--blue-fade);
    --blue-counter-font-color:          var(--blue);
    --red-counter-background-color:     var(--red-fade);
    --red-counter-font-color:           var(--red);
    --orange-counter-background-color:  var(--orange-fade);
    --orange-counter-font-color:        var(--orange);
    --yellow-counter-background-color:  var(--yellow-fade);
    --yellow-counter-font-color:        var(--yellow);
    --grey-counter-background-color:    var(--grey-fade);
    --grey-counter-font-color:          var(--grey);
    --counter-font-size:                12px;
    --counter-width:                    80px;

    /* Buttons */
    --comment-button-background-color:  var(--blue);
    --comment-button-font-color:        var(--white);
    --mark-button-background-color:     var(--green);
    --mark-button-font-color:           var(--white);
}

