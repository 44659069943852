@import './Styles/global.css';

.cardBody{
    min-height: 150px;
}
.arrow{
    z-index: 100;
}

.btnGroup {
    margin-left: 5px;
}

.li{
    margin-bottom: 1%;
}

.blocksHeading{
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-subheading-size);
  line-height: 20px;
  
  margin-left: 0.25vw;
  color: var(--font-subheading-color);
}

.blueCounter,
.greenCounter,
.redCounter,
.greyCounter,
.orangeCounter {
  display: inline-block;
  text-align: center;
  padding: 2px 10px 2px 10px;
  font-size: var(--counter-font-size);
}
.deviceInfo {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 18px;
  
  /* identical to box height */
  display: flex;
  align-items: center;
  
  color: #6C757D;
}
.blueCounter{
  color: var(--blue-counter-font-color);
  background: var(--blue-counter-background-color);
}
.greenCounter{
  color: var(--green-counter-font-color);
  background: var(--green-counter-background-color);
}
.greyCounter{
  color: var(--grey-counter-font-color);
  background: var(--grey-counter-background-color);
}
.redCounter{
  color: var(--red-counter-font-color);
  background: var(--red-counter-background-color);
}
.orangeCounter{
  color: var(--orange-counter-font-color);
  background: var(--orange-counter-background-color);
}

.blocksBody{
  font-size: var(--font-normal-size);
}

.blocksRow{
  margin-top:5px;
  margin-left:0.5vw;
}

.blocksCol{
  width:30vw;
}

.blueButton {
  background: #35A8E0;
  border-color: #35A8E0;
  width: 30px;
  height: 30px;
  padding: 0;

  float: right;
}
.downloadButton {
  background: #35A8E0;
  border-color: #35A8E0;
  margin-top: 20px;
  /* width: 30px; */
  height: 30px;
  padding-bottom: 0;
  padding-top: 0;
  padding-left: "10px";
  padding-right: "10px";
  float: right;
}
.cardHeader{
  display: grid;
  grid-template-columns: 30px auto auto;
  align-items: center;

  font-size: var(--card-header-font-size);
  color: var(--card-header-font-color);
  font-family: Quicksand;
  font-style: normal;
  font-weight: 400;
  background-color: var(--card-header-background-color);
}

.cardHeaderNoImg{
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;

  font-size: var(--card-header-font-size);
  color: var(--card-header-font-color);
  font-family: Quicksand;
  font-style: normal;
  font-weight: 400;
  background-color: var(--card-header-background-color);
}

.cardHeader > img {
  width: 20px;
}

.formInline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

/* Add some margins for each label */
.formInline label {
  margin: 5px 10px 5px 0;
}

/* Style the input fields */
.formInline input {
  vertical-align: middle;
  margin: 5px 10px 5px 0;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
}

tableRowReqSelect,
.tableRowHeader,
.tableRowReq{
  border-width: 1px !important;
  border: lightgray;
  border-top-style: none;
  border-top-width: medium;
  border-right-style: none;
  border-right-width: medium;
  border-bottom-style: none;
  border-bottom-width: medium;
  border-left-style: none;
  border-left-width: medium;
  border-style: solid;
  border-radius: 5px;
  padding: 5px;
  cursor:pointer;
  color:black;
  /* text-decoration:underline; */
  margin-bottom: -4px;
}

.testLink{
  width: 100%;
  color:black;
  text-decoration: none !important;    
}

.testLink:hover{
  color:black !important;
  text-decoration: none !important;   
}

.tableRowReq:hover{
  background-color: #e6e6e6 ;
}

.stickyWindow {
  /* position: -webkit-sticky; Safari */
  position: fixed;
  width: 27vw;
  z-index: 100;
  /* margin-top: 8vh; */
  /* top: 100vh; */
}

.visuallyHidden{
  display: none;
}

.boardsSwitch {
    padding-bottom: 5%;
    margin-right: 3%;
  }
.tableSwitch {
    padding-bottom: 5%;
    margin-left: 1%;
}

.deviceOverview {
  display: grid;
  grid-template-columns: 100%;

  /* max-width: 940px; */
}

.numberStatus {
  display: grid;
  grid-template-columns: repeat(6, auto);
  text-align: center;
}

/* .page-info {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(640px, 1fr));
} */

/* .device-info-box {
  display: grid; 
  grid-template-columns: 2fr 1fr 1fr 1fr;
} */

/* .device-info {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 18px;

  /* identical to box height */
  /*display: flex;
  align-items: center;

  color: #6C757D;
} */

/* .device-description-box {
  display: grid;
  grid-template-columns: 100px auto;
} */

/* .device-details {
  width: 50%;
  min-width: 640px;
} */

.deviceHeading{
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-heading-size);
  line-height: 22px;
  
  color: var(--font-heading-color);
}

.deviceSubheading {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-subheading-size);
  line-height: 20px;

  /* identical to box height */
  display: flex;
  align-items: center;

  color: var(--font-subheading-color);
}

.descText{
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-normal-size);

  color: var(--font-normal-color);
}

.lookingForSomething{
  min-width: 640px;
  background: #FFFFFF80;
  padding: 10px 20px;
}

.lookingForSomething > h5 {
  font-size: var(--font-subheading-size);
  color: var(--font-normal-color);
}

.lookingForLink{
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-link-size);
  color: var(--font-link-color);
  line-height: 18px;
  margin: 10px 10px 0 0;

  text-align: center;
}

.cardContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 360px));
  gap: 30px 60px;
}

.cardStatus {
  display: grid;
  grid-template-columns: auto 120px;
}

.wordWrap {
  display: grid;
  grid-template-columns: auto auto;
  gap: 4px;
}

.requirementTitle {
  font-size: var(--font-heading-size);
  width: 57%;
  align-items: center;
}

.requirementTitle > button {
  margin-bottom: 10px !important;
  margin-left: 20px;
}

.deviceCard {
  min-height: 180px;
}