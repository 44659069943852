@import '../../../../components/Styles/global.css';

.tableContainer {
  background: var(--white);
  padding: 10px 20px;
  padding-top: 20px;
  overflow-x: scroll;
}

.matrixContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, calc(50% - 5px));
  gap: 10px;

  margin: 20px 0;
}

.matrixTitle {
  color: var(--font-heading-color);
  font-size: var(--font-heading-size);
}

.matrixPlaceholder {
  text-align: center;
  padding: 0.75rem;
  vertical-align: top;
  font-size: var(--table-body-font-size) !important;
}

.matrixDropdown {
  background-color: var(--white);
  border: 1px solid var(--grey-fade-half);
  border-radius: 4px;
  padding: 10px;
  width: 100%;
}