@import '../../../../components/Styles/global.css';

.blocksHeading{
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-subheading-size);
  line-height: 20px;
  
  margin-left: 0.25vw;
  color: var(--font-subheading-color);
}

.cardContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 360px));
  gap: 30px 60px;
}

.deviceCard {
  min-height: 180px;
}

.blueButton {
  background: #35A8E0 !important;
  border-color: #35A8E0 !important;
  width: 30px !important;
  height: 30px !important;
  padding: 0 !important;

  float: right !important;
}

.blocksBody{
  font-size: var(--font-normal-size);
}

.numberStatus {
  display: grid;
  grid-template-columns: repeat(6, auto);
  text-align: center;
}

.downloadButton {
  background: #35A8E0 !important;
  border-color: #35A8E0 !important;
  margin-top: 20px;
  /* width: 30px; */
  height: 30px;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  padding-left: "10px";
  padding-right: "10px";
  float: right;
}