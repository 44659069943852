@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');

body {
    font-family: 'Quicksand', sans-serif !important;
}

.btn-primary {
    color: #fff;
    background-color: #35a8e0;
    border-color: #35a8e0;
  }