@import '../../../../components/Styles/global.css';

.cardHeader{
  min-height: 10vh;
  height: auto;
  font-size: var(--card-header-font-size);
  color: var(--card-header-font-color);
  background-color: var(--card-header-background-color);
  font-family: Quicksand;
  font-style: normal;
  font-weight: 400;
}

.cardHeadTitle { 
  border-bottom: var(--light-border);
}

.card {
  box-shadow: none;
  border: none;
  background-color: #fff0;
}

.cardBorderB {
  border-bottom: none;
  box-shadow: none;
  border-radius: 0;
}

.deviceInfo {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 18px;
  
  /* identical to box height */
  display: flex;
  align-items: center;
  
  color: #6C757D;
}
  
.greenCounter,
.blueCounter,
.redCounter,
.greyCounter,
.orangeCounter {
  display: inline-block;
  min-width: 4.5vw;
  text-align: center;
  padding: 2px 10px 2px 10px;
}

.blueCounter{
  color: var(--blue-counter-font-color) !important;
  background: var(--blue-counter-background-color) !important;
}
.greenCounter{
  color: var(--green-counter-font-color) !important;
  background: var(--green-counter-background-color) !important;
}
.greyCounter{
  color: var(--grey-counter-font-color) !important;
  background: var(--grey-counter-background-color) !important;
}
.redCounter{
  color: var(--red-counter-font-color) !important;
  background: var(--red-counter-background-color) !important;
}

.orangeCounter{
  color: var(--orange-counter-font-color) !important;
  background: var(--orange-counter-background-color) !important;
}

.deviceSubheading {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-subheading-size);
  line-height: 20px;

  /* identical to box height */
  display: flex;
  align-items: center;

  color: var(--font-subheading-color);
}

.deviceHeading{

  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-heading-size);
  line-height: 22px;
  
  /* identical to box height */
  /* display: flex; */
  /* align-items: center; */
  
  color: var(--font-heading-color);
}

.cardBody{
    min-height: 20vh;
}

.arrow{
    z-index: 100;
}

.btnGroup {
    margin-left: 5px;
    margin-bottom: 5px;
}

.li{
    margin-bottom: 1%;
}

.formInline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

/* Add some margins for each label */
.formInline label {
  margin: 5px 10px 5px 0;
}

/* Style the input fields */
.formInline input {
  vertical-align: middle;
  margin: 5px 10px 5px 0;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
}

.tableRowReq{
  border-bottom: var(--light-border);
  padding: 5px;
  cursor:pointer;
  color:var(--card-header-font-color);
  /* text-decoration:underline; */
  margin-bottom: -4px;
}

.testLink{
  width: 100%;
  color:black;
  text-decoration: none !important;    
}

.testLink:hover{  
  color:black !important;
  text-decoration: none !important;   
}

.tableRowHeader{
  border-width: 1px !important;
  border: lightgray;
  border-top-style: none;
  border-top-width: medium;
  border-right-style: none;
  border-right-width: medium;
  border-bottom-style: none;
  border-bottom-width: medium;
  border-left-style: none;
  border-left-width: medium;
  border-style: solid;
  border-radius: 5px;
  padding: 5px;
  cursor:pointer;
  color:black;
  /* text-decoration:underline; */
  margin-bottom: -4px;
}

.tableRowReq:hover{
  background-color: #e6e6e6 ;
}

.stickyWindow {
  /* position: -webkit-sticky; Safari */
  position: fixed;
  width: 27vw;
  z-index: 100;
  /* margin-top: 8vh; */
  /* top: 100vh; */
}

.visuallyHidden{
  display: none;
}

.tableRowReqSelect{
  border-width: 1px !important;
  border: lightgray;
  border-top-style: none;
  border-top-width: medium;
  border-right-style: none;
  border-right-width: medium;
  border-bottom-style: none;
  border-bottom-width: medium;
  border-left-style: none;
  border-left-width: medium;
  border-style: solid;
  border-radius: 5px;
  padding: 5px;
  cursor:pointer;
  color:black;
  /* text-decoration:underline; */
  margin-bottom: -4px;
  background-color: #e6e6e6 ;
}

.boardsSwitch {
  padding-bottom: 5%;
  margin-right: 3%;
}

.tableSwitch {
  padding-bottom: 5%;
  margin-left: 1%;
}

.requirementRow {
  padding: 0.5rem 0;
}

.testContainer {
  width: 100%;
}

.requirementContainer {
  margin-bottom: 0;

  /* border-bottom: var(--light-border); */
}

.requirementInfo {
  display: grid;
  grid-template-columns: 3fr 1fr;
  align-items: center;

  margin: 0 0;
  padding: 0;
}

.requirementInfoElement {
  width: 100%;
  padding: 10px 0;
}

.requirementGroup {
  margin: 0;
}

.requirementList {
  border: var(--light-border);
  padding: 0;
  margin: 0;
  max-height: 60vh;
  overflow-y: scroll;
}

.tileListContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));

  margin-top: 20px;
  width: 100%;
}

.tasksReqs {
  width: auto;
  padding: 0 5px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.taskHeader {
  font-size: var(--font-heading-size);
  color: var(--font-heading-color);

}

.tileContainer {
  display: grid;
  grid-template-columns: 4fr 1fr 0 1fr;
}

.tilesList {
  padding: 10px 20px;
  overflow-y: auto;
  overflow-x: visible;
  height: 50vh;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: calc(.25rem - 1px);
}

.sectionTitle {
  font-size: 1rem;
}

.cardDesc {
  text-align: left;
}

.cardDescHeader {
  background-color: var(--card-header-background-color);
  font-size: var(--card-header-font-size);
  color: var(--card-header-font-color);
  text-align: left;
}

.cardDescBody {
  background-color: var(--card-body-background-color);
  font-size: var(--card-body-font-size);
  color: var(--card-body-font-color);
  text-align: left;
}

.deviceHeading{
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-heading-size);
  line-height: 22px;

  color: var(--font-heading-color);
}

.deviceSubheading {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-subheading-size);
  line-height: 20px;

  /* identical to box height */
  display: flex;
  align-items: center;

  color: var(--font-subheading-color);

  margin-bottom: 40px;
}

.buttonGroup {
  display: grid;
  /* max-width: 250px; */
  grid-template-columns: 5fr 1fr;
}

.buttonRow {
  padding: 0 5px;
}

.buttonRow > div {
  margin-right: 5px;
}

.buttonCollection {
  display: grid;
  grid-template-columns: auto;
  gap: 5px;
  padding: 0 15px;
}

.secondaryButtons {
  display: grid;
  grid-template-columns: 100px 150px;
  padding: 0 5px;
}

.noTitle {
  color: rgb(204, 204, 204);
}

.title {
  text-align: left;
}

.ref {
  text-align: right;
  float: right;
  margin-right: calc(1.5rem + 5px);
}

/* .title {
  text-align: right;
  float: right;
  margin-right: calc(1.5rem + 5px);
}

.ref {
  text-align: left;
  
}  */

.requirementContent {
  overflow-y: auto;
  max-height: 256px;
}

.regulationHead {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding: 10px calc(1.5rem * .5 + 1.25rem);
  border-bottom: var(--light-border);
}

.requirementTabs {
  display: grid;
  grid-template-columns: 100px 1px 100px;
  align-items: center;
  border-bottom: 1px solid #6C757D;
  padding: 5px 0;
  cursor: pointer;
}

.requirementTab {
  font-size: 1rem;
  /* color: #35A8E0; */
  text-align: center;
}
.reqCrossRef {
  
  /* color: #35A8E0; */
  text-align: left;
  cursor: pointer;
}
.requirementDivider {
  border-left: 1px solid #6C757D;
  height: 28px;
}

