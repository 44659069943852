@import './global.css';

/* Main Table */
.table {
    border: var(--light-border) !important;
}

.tableHead {
    background-color: var(--table-header-background-color);
    font-size: var(--table-header-font-size);
    color: var(--table-header-font-color);

    border: var(--light-border) !important;
}

.tableTH {
    background-color: var(--table-header-background-color) !important;
    font-size: var(--table-header-font-size);
    color: var(--table-header-font-color);
    text-align: left;
    border-bottom-width: 2px !important;
    border-bottom-color: #dee2e6 !important;

    /* border: var(--light-border) !important; */
}

.tableTD {
    background-color: var(--table-body-background-color) ;
    font-size: var(--table-body-font-size) !important;
    color: var(--table-body-font-color) !important;
    text-align: left;

    border: var(--light-border) !important;
}

.tableTitle {
    background-color: var(--table-header-background-color) !important;
    font-size: var(--table-header-font-size);
    color: var(--table-header-font-color);
    text-align: left;

    border-right: 2px solid black;
}

.tableTVH {
    background-color: var(--table-body-background-color) !important;
    font-size: var(--table-body-font-size);
    color: var(--table-body-font-color);
    text-align: left;

    border-right: 2px solid black;
}

.tableButtonContainer {
    display: flex;
    justify-content: space-between;
}