@import './global.css';

/* Card Main */
.cardContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(340px, 400px));
}

.card {
    border: 0 !important;
}

.cardBorder {
    border: var(--light-border);
    
}

.cardBorderB {
    border-bottom: var(--light-border);
}

.card,
.cardBorder,
.cardBorderB {
    box-shadow: none !important;
    border-radius: 0 !important;
}

.cardBody{
    font-size: var(--card-body-font-size);
    padding: 10px 20px;
    background-color: var(--card-body-background-color) !important; 
    box-shadow: none;
    border: 0;
}

.cardFooter {
    font-size: var(--card-body-font-size);
    padding: 10px 20px !important;
    background-color: var(--card-body-background-color) !important;
    box-shadow: none;
    border: 0 !important;
}

.cardHeaderNoBorder,
.cardHeader{
    font-size: var(--card-header-font-size);
    color: var(--card-header-font-color) !important; 
    font-family: Quicksand;
    font-style: normal;
    font-weight: 400;
    background-color: var(--card-header-background-color) !important;
}

.cardHeaderNoBorder {
    border-bottom: 0 !important;
}

.cardHeader {
    border-bottom: var(--card-header-border);
}

.cardSubheading {
    font-size: var(--card-subheading-font-size);
    color: var(--card-subheading-font-color) !important; 
}

/* Card Elements */
.blueCounter,
.greenCounter,
.redCounter,
.greyCounter,
.yellowCounter,
.orangeCounter {
    display: inline-block;
    text-align: center;
    padding: 2px 10px 2px 10px;
    font-size: var(--counter-font-size);
}

.blueCounter{
    color: var(--blue-counter-font-color) !important; 
    background: var(--blue-counter-background-color) !important; 
}

.greenCounter{
    color: var(--green-counter-font-color) !important; 
    background: var(--green-counter-background-color)!important;
}

.greyCounter{
    color: var(--grey-counter-font-color) !important; 
    background: var(--grey-counter-background-color) !important; 
}

.redCounter{
    color: var(--red-counter-font-color) !important; 
    background: var(--red-counter-background-color) !important; 
}

.orangeCounter{
    color: var(--orange-counter-font-color) !important; 
    background: var(--orange-counter-background-color) !important; 
}

.yellowCounter {
    color: var(--yellow-counter-font-color) !important; 
    background: var(--yellow-counter-background-color) !important; 
}

.numberStatus {
    display: grid;
    grid-template-columns: repeat(3, 33%);
}