@import './global.css';

.deviceInfo {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 18px;
    
    /* identical to box height */
    display: flex;
    align-items: center;
    
    color: #6C757D;

    margin-bottom: 1.5rem;
  }
    
  .greenCounter,
  .blueCounter,
  .redCounter,
  .greyCounter,
  .orangeCounter {
    display: inline-block;
    min-width: 4.5vw;
    text-align: center;
    padding: 2px 10px 2px 10px;
    margin: 0 5px;
  }
  
  .blueCounter{
    color: var(--blue-counter-font-color);
    background: var(--blue-counter-background-color);
  }
  .greenCounter{
    color: var(--green-counter-font-color);
    background: var(--green-counter-background-color);
  }
  .greyCounter{
    color: var(--grey-counter-font-color);
    background: var(--grey-counter-background-color);
  }
  .redCounter{
    color: var(--red-counter-font-color);
    background: var(--red-counter-background-color);
  }
  
  .orangeCounter{
    color: var(--orange-counter-font-color);
    background: var(--orange-counter-background-color);
  }
  
  .deviceSubheading {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-subheading-size);
    line-height: 20px;
  
    /* identical to box height */
    display: flex;
    align-items: center;
  
    color: var(--font-subheading-color);
  }
  
  .deviceHeading{
  
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-heading-size);
    line-height: 22px;
    margin-bottom: 1.5rem;
    
    /* identical to box height */
    /* display: flex; */
    /* align-items: center; */
    
    color: var(--font-heading-color);
  }